const apiHost = "zoomyear.com";
const apiPort = 5001;

const getEnvironment = () => {
  let environment = "development";
  if (location.hostname !== "localhost" && location.hostname !== "127.0.0.1") {
    environment = "production";
  }
  return environment;
};
const environment = getEnvironment();
const isProduction = environment === "production";

// get leaderboard data

const fillLeaderboard = (selector, gameId, data) => {
  const n = document.getElementById(selector);
  n.innerHTML = "";
  const t = document.createElement("table");
  t.style =
    "width: calc(100% - 160px); margin: 0px 80px; border-collapse: collapse;";
  const json = JSON.parse(data);
  const o = document.createElement("tbody");
  json[gameId].forEach((e, n) => {
    const t = document.createElement("tr");
    t.classList.add("leaderboard__list__row");
    const position = document.createElement("td");
    const a = document.createElement("td"),
      s = document.createElement("td"),
      l = document.createElement("td");
    position.style = "width: 20px; font-size: 12px; color: lightgray;";
    position.innerHTML = n + 1;
    (a.style = "width: 30px"),
      (s.style = "text-align: left"),
      (l.style = "text-align: right");
    const r = e.countryCode && e.countryCode.toLowerCase();
    (a.innerHTML = "<span class='flag-icon flag-icon-" + r + "'></span>"),
      (s.innerHTML = e.name),
      (l.innerHTML = e.points),
      t.appendChild(a),
      t.appendChild(s),
      t.appendChild(l),
      o.appendChild(t);
    a.innerHTML = "<span class='flag-icon flag-icon-" + r + "'></span>";
    s.innerHTML = e.name;
    l.innerHTML = e.points;
    if (0 === n) {
      const e = document.createElement("img");
      (e.src = "/assets/trophies/trophy-gold-icon.png"), s.appendChild(e);
      e.alt = "gold";
    } else if (1 === n) {
      const e = document.createElement("img");
      (e.src = "/assets/trophies/trophy-silver-icon.png"), s.appendChild(e);
      e.alt = "silver";
    } else if (2 === n) {
      const e = document.createElement("img");
      (e.src = "/assets/trophies/trophy-copper-icon.png"), s.appendChild(e);
      e.alt = "copper";
    }
    t.appendChild(position);
    t.appendChild(a), t.appendChild(s), t.appendChild(l), o.appendChild(t);
  });
  t.appendChild(o);
  n.appendChild(t);
};

const fillLeaderboardMonth = (data) => {
  const n = document.getElementById("leaderboard__list-month");
  n.innerHTML = "";
  const t = document.createElement("table");
  t.style =
    "width: calc(100% - 160px); margin: 0px 80px; border-collapse: collapse;";
  const json = JSON.parse(data);
  const o = document.createElement("tbody");
  json.month.forEach((e, n) => {
    const t = document.createElement("tr");
    t.classList.add("leaderboard__list__row");
    const position = document.createElement("td");
    const a = document.createElement("td"),
      s = document.createElement("td"),
      l = document.createElement("td");
    position.style = "width: 20px; font-size: 12px; color: lightgray;";
    position.innerHTML = n + 1;
    (a.style = "width: 30px"),
      (s.style = "text-align: left"),
      (l.style = "text-align: right");
    const r = e.countryCode && e.countryCode.toLowerCase();
    (a.innerHTML = "<span class='flag-icon flag-icon-" + r + "'></span>"),
      (s.innerHTML = e.name),
      (l.innerHTML = e.points),
      t.appendChild(a),
      t.appendChild(s),
      t.appendChild(l),
      o.appendChild(t);
    a.innerHTML = "<span class='flag-icon flag-icon-" + r + "'></span>";
    s.innerHTML = e.name;
    l.innerHTML = e.points;
    if (0 === n) {
      const e = document.createElement("img");
      (e.src = "/assets/trophies/trophy-gold-icon.png"), s.appendChild(e);
      e.alt = "gold";
    } else if (1 === n) {
      const e = document.createElement("img");
      (e.src = "/assets/trophies/trophy-silver-icon.png"), s.appendChild(e);
      e.alt = "silver";
    } else if (2 === n) {
      const e = document.createElement("img");
      (e.src = "/assets/trophies/trophy-copper-icon.png"), s.appendChild(e);
      e.alt = "copper";
    }
    t.appendChild(position);
    t.appendChild(a), t.appendChild(s), t.appendChild(l), o.appendChild(t);
  });
  t.appendChild(o);
  n.appendChild(t);
};

const fillLeaderboardLast = (data) => {
  const n = document.getElementById("last_records__list");
  n.innerHTML = "";
  const t = document.createElement("table");
  t.style =
    "width: calc(100% - 160px); margin: 0px 80px; border-collapse: collapse;";
  const json = JSON.parse(data);
  const o = document.createElement("tbody");
  json.last.forEach((e) => {
    const t = document.createElement("tr");
    t.classList.add("leaderboard__list__row");
    const a = document.createElement("td"),
      s = document.createElement("td");
    const gameId = document.createElement("td");
    l = document.createElement("td");
    gameId.style = "color: gray; font-size: 12px";
    (a.style = "width: 30px"),
      (s.style = "text-align: left"),
      (l.style = "text-align: right");
    const r = e.countryCode && e.countryCode.toLowerCase();
    (a.innerHTML = "<span class='flag-icon flag-icon-" + r + "'></span>"),
      (s.innerHTML = e.name),
      (gameId.innerHTML = e.gameId);
    (l.innerHTML = e.points), t.appendChild(gameId);
    t.appendChild(a), t.appendChild(s), t.appendChild(l), o.appendChild(t);
    a.innerHTML = "<span class='flag-icon flag-icon-" + r + "'></span>";
    s.innerHTML = e.name;
    l.innerHTML = e.points;
    t.appendChild(a), t.appendChild(s), t.appendChild(l), o.appendChild(t);
  });
  t.appendChild(o);
  n.appendChild(t);
};

httpGetAsync(
  `${isProduction ? "https" : "http"}://${
    isProduction ? `${apiHost}:${apiPort}` : `localhost:${apiPort}`
  }/api/zoomtastic-year/leaderboards`,
  (response) => {
    fillLeaderboard("leaderboard__list", "standard", response);
    fillLeaderboardMonth(response);
    fillLeaderboardLast(response);
  }
);

// get announcement
httpGetAsync(
  `${isProduction ? "https" : "http"}://${
    isProduction ? `${apiHost}:${apiPort}` : `localhost:${apiPort}`
  }/api/zoomtastic-year/announcement`,
  (response) => {
    const json = JSON.parse(response);
    const announcement = json.find(
      (x) =>
        x.locale === localStorage.getItem("gameLocale", window.gameLocale) ||
        window.gameLocale
    );
    const banner = document.getElementById("announcement-banner");
    banner.style.display = "block";
    if (announcement) {
      banner.innerHTML = announcement.text;
    } else {
      banner.innerHTML = json.find((x) => x.locale === "en")?.text || "";
    }
  }
);
